import type { Branch } from "~/types/Branch";
import type {
  FeaturedRestaurant,
  Restaurant,
  RestaurantTags,
} from "~/types/Restaurant";
import type { SearchSuggestion } from "~/types/SearchSuggestion";

type PartialNested<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? PartialNested<U>[]
    : T[P] extends object
    ? PartialNested<T[P]>
    : T[P];
};

export default function adsMapper(
  restaurantAd:
    | FeaturedRestaurant
    | Restaurant
    | RestaurantTags
    | Branch
    | SearchSuggestion,
  restaurantArray:
    | FeaturedRestaurant[]
    | Restaurant[]
    | RestaurantTags[]
    | Branch[]
    | SearchSuggestion[] = [],
  pageSize: number,
  pageNumber: number
) {
  const restaurantAdAttribute =
    "attributes" in restaurantAd ? restaurantAd.attributes : restaurantAd;
  restaurantAdAttribute.isAds = true;
  const adsPosition = restaurantAdAttribute.position;
  if (
    Array.isArray(restaurantArray) === false ||
    (Array.isArray(restaurantArray) && restaurantArray.length === 0)
  ) {
    return;
  }
  const endIndex = pageNumber * pageSize;
  const startIndex = endIndex - pageSize + 1;

  if (
    typeof adsPosition === "number" &&
    adsPosition >= startIndex &&
    adsPosition <= endIndex
  ) {
    const parsedPosition = adsPosition > 0 ? adsPosition - 1 : 1;
    const findIndex = restaurantArray.findIndex(
      (restaurant) => restaurant.id === restaurantAd.id
    );
    // ads data is exist on restaurant array
    if (findIndex !== -1) {
      // if ads position is in 0 or ads postion is bigger or equal than the restaurant than make the restaurant isAds attributes to true
      if (
        (parsedPosition === 0 && findIndex === 0) ||
        parsedPosition >= findIndex
      ) {
        const restaurant = restaurantArray[findIndex];
        if ("attributes" in restaurant) {
          restaurant.attributes.isAds = true;
        } else {
          restaurant.isAds = true;
        }
        // restaurantArray[findIndex].attributes.isAds = true;
      }
      // if ads position is smaller than the restaurant than swap the index
      else if (parsedPosition < findIndex) {
        const restaurant = restaurantArray[findIndex];
        // set restaurant isAds attributes to be true
        if ("attributes" in restaurant) {
          restaurant.attributes.isAds = true;
        } else {
          restaurant.isAds = true;
        }
        restaurantArray.splice(findIndex, 1);
        // @ts-ignore
        restaurantArray.splice(parsedPosition, 0, restaurant);
      }
    } else if (parsedPosition === 0) {
      // @ts-ignore
      restaurantArray.unshift(restaurantAd);
    } else {
      // @ts-ignore
      restaurantArray.splice(parsedPosition, 0, restaurantAd);
    }
  }
}
